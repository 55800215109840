<template>
  <div count-view :class="{white}">
    <p>{{ title }}</p>
    <ul v-if="countMap">
      <li v-for="(item,id) in countMap" :key="id"><p>{{ item }}</p></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CountView',
  components: {},
  props: {
    white: { type: Boolean, default: true },
    length: Number,
    value: { type: Number, default: 0 },
    title: String,
    prxs: { type: Array, default: () => [] },
  },
  data() {
    return {
      countMap: null,
      type: 'progress',
    };
  },
  watch: {
    value: 'init',
  },
  methods: {
    init() {
      const valueMap = this.value ? String(this.value).split('') : ['0'];
      this.countMap = new Array(this.length);
      for (let i = 0; i < this.length; i += 1) {
        if (i + 1 > valueMap.length) {
          this.countMap[this.countMap.length - (i + 1)] = '0';
        } else {
          const v = valueMap[valueMap.length - (1 + i)];
          this.countMap[this.countMap.length - (i + 1)] = Number(v);
        }
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[count-view] { .rel; .pt(36); .ib;
  > p {.tc; .fs(16); .mb(12); .abs; .w(100%); .lt(50%, 0); .t-xc;}
  > ul {.ib-list;
    > li {.wh(40, 60); .bold; .tc; .br(4); .bgc(#363840); .ml(6); overflow: hidden; .rel;
      > p {.wh(100%, 40); .fs(30, 40px); .abs; .lt(0, 50%); .mt(-20); .font-russo;}
      &:nth-of-type(1) {.ml(0);}
    }
  }
  @media (@tl-up) {
    > p {.fs(20);}
    > ul {
      > li {.wh(60, 70); .ml(8);
        > p {.wh(100%, 40); .fs(30, 40px);}
      }
    }
  }
  @media (@ds-up) {

  }
}
</style>
